import React from "react";
import Navbar from "../components/Navbar";
import { Outlet } from "react-router-dom";

function MainHeader() {
  return (
    <>
      <div className="flex flex-col h-screen w-full">
          <Navbar />
          <div className="h-full mt-[10vh] overflow-x-hidden">
            <Outlet />
          </div>
        </div>
    </>
  );
}

export default MainHeader;
